define("discourse/plugins/partly-discourse-plugin/discourse/routes/brands", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Route as defined in `../partly-route-map.js.es6`.
   */
  var _default = _exports.default = _discourse.default.extend({
    queryParams: {
      id: {
        refreshModel: true
      }
    },
    renderTemplate() {
      this.render('brands');
    }
  });
});