define("discourse/plugins/partly-discourse-plugin/discourse/templates/standard-overview", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <iframe src="https://v2-embednotion.com/1392cf966862809c82c8fc49dbbfe79e"
          style="width: 100%; height: 100%; border: 2px solid #ccc; border-radius: 10px;"></iframe>
  */
  {
    "id": "uVCyKtB/",
    "block": "[[[10,\"iframe\"],[14,\"src\",\"https://v2-embednotion.com/1392cf966862809c82c8fc49dbbfe79e\"],[14,5,\"width: 100%; height: 100%; border: 2px solid #ccc; border-radius: 10px;\"],[12],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/partly-discourse-plugin/discourse/templates/standard-overview.hbs",
    "isStrictMode": false
  });
});