define("discourse/plugins/partly-discourse-plugin/discourse/templates/landing-page", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h1>Data Standard</h1>
  
  <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 10px">
      <a href="/standard-overview" class="btn btn-text btn-default bootstrap-mode">
          <span class="d-button-label">Data Standard overview</span>
      </a>
  
      <a href="/catalog" class="btn btn-text btn-default bootstrap-mode">
          <img src="/plugins/partly-discourse-plugin/images/catalog-icon-black.svg" height="16px"
               style="margin-right: 4px" alt=""/>
          <span class="d-button-label">Parts Standard</span>
      </a>
  
      <a href="/brands" class="btn btn-text btn-default bootstrap-mode">
          <img src="/plugins/partly-discourse-plugin/images/catalog-icon-black.svg" height="16px"
               style="margin-right: 4px" alt=""/>
          <span class="d-button-label">Brands Standard</span>
      </a>
  
      <a href="/vehicles" class="btn btn-text btn-default bootstrap-mode">
          <img src="/plugins/partly-discourse-plugin/images/uvdb-icon-black.svg" height="16px"
               style="margin-right: 4px" alt=""/>
          <span class="d-button-label">Vehicle Standard</span>
      </a>
  </div>
  */
  {
    "id": "bCHxTjW5",
    "block": "[[[10,\"h1\"],[12],[1,\"Data Standard\"],[13],[1,\"\\n\\n\"],[10,0],[14,5,\"display: flex; flex-direction: column; align-items: flex-start; gap: 10px\"],[12],[1,\"\\n    \"],[10,3],[14,6,\"/standard-overview\"],[14,0,\"btn btn-text btn-default bootstrap-mode\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"d-button-label\"],[12],[1,\"Data Standard overview\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,3],[14,6,\"/catalog\"],[14,0,\"btn btn-text btn-default bootstrap-mode\"],[12],[1,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/plugins/partly-discourse-plugin/images/catalog-icon-black.svg\"],[14,\"height\",\"16px\"],[14,5,\"margin-right: 4px\"],[14,\"alt\",\"\"],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"d-button-label\"],[12],[1,\"Parts Standard\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,3],[14,6,\"/brands\"],[14,0,\"btn btn-text btn-default bootstrap-mode\"],[12],[1,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/plugins/partly-discourse-plugin/images/catalog-icon-black.svg\"],[14,\"height\",\"16px\"],[14,5,\"margin-right: 4px\"],[14,\"alt\",\"\"],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"d-button-label\"],[12],[1,\"Brands Standard\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,3],[14,6,\"/vehicles\"],[14,0,\"btn btn-text btn-default bootstrap-mode\"],[12],[1,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/plugins/partly-discourse-plugin/images/uvdb-icon-black.svg\"],[14,\"height\",\"16px\"],[14,5,\"margin-right: 4px\"],[14,\"alt\",\"\"],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"d-button-label\"],[12],[1,\"Vehicle Standard\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/partly-discourse-plugin/discourse/templates/landing-page.hbs",
    "isStrictMode": false
  });
});