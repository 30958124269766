define("discourse/plugins/partly-discourse-plugin/discourse/components/catalog-icon", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <img src="/plugins/partly-discourse-plugin/images/catalog-icon-black.svg" height="32px" alt=""/>
  */
  {
    "id": "VJ+G+a8d",
    "block": "[[[10,\"img\"],[14,\"src\",\"/plugins/partly-discourse-plugin/images/catalog-icon-black.svg\"],[14,\"height\",\"32px\"],[14,\"alt\",\"\"],[12],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/partly-discourse-plugin/discourse/components/catalog-icon.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "catalog-icon"));
});